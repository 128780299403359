//const API_URL = 'http://localhost:5000/api';
const API_URL = 'https://leads-expert-be.vercel.app/api';

export const login = async (mobile, password) => {
  try {
    console.log('Attempting login with:', { mobile, password });
    const response = await fetch(`${API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mobile, password }),
    });
    console.log('Login response status:', response.status);
    
    const contentType = response.headers.get("content-type");
    let data;
    if (contentType && contentType.indexOf("application/json") !== -1) {
      data = await response.json();
    } else {
      data = { message: await response.text() };
    }
    console.log('Login response data:', data);
    
    if (!response.ok) {
      throw new Error(data.message || 'Login failed');
    }
    
    if (!data.token) {
      console.error('No token received from server');
      throw new Error('No token received from server');
    }
    
    // Store the token in localStorage
    localStorage.setItem('token', data.token);
    console.log('Token stored in localStorage:', data.token);
    
    return data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const register = async (mobile, name, password) => {
  try {
    const response = await fetch(`${API_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ mobile, name, password })
    });
    
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || 'Registration failed');
    }
    
    return { status: response.status, data };
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

export const forgotPassword = async (mobile) => {
  try {
    const response = await fetch(`${API_URL}/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ mobile })
    });

    // Check if the response is JSON
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to process forgot password request');
      }
      return data;
    } else {
      // If it's not JSON, read it as text
      const text = await response.text();
      console.error('Unexpected response:', text);
      throw new Error('Received an unexpected response from the server');
    }
  } catch (error) {
    console.error('Error in forgotPassword:', error);
    throw error;
  }
};

export const getLeads = async (page = 1, limit = 5, search = '') => {
  console.log('API: Fetching leads. Page:', page, 'Limit:', limit, 'Search:', search);
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${API_URL}/leads?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('API: Leads response status:', response.status);
    const data = await response.json();
    console.log('API: Leads data:', data);
    return data;
  } catch (error) {
    console.error('API: Error fetching leads:', error);
    throw error;
  }
};

export const getPurchasedLeads = async (page = 1, limit = 10) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }

  try {
    const response = await fetch(`${API_URL}/purchased-leads?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching purchased leads:', error);
    throw error;
  }
};

export const getUserProfile = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found in getUserProfile');
    throw new Error('No token found. Please log in again.');
  }
  
  try {
    console.log('Fetching user profile with token:', token);
    const response = await fetch(`${API_URL}/user-profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`  // Changed from 'x-access-token' to 'Authorization'
      }
    });
    
    console.log('User profile response status:', response.status);

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error fetching user profile:', errorData);
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    console.log('User profile data:', data);
    return data;
  } catch (error) {
    console.error('Error in getUserProfile:', error);
    throw error;
  }
};

export const updateUserProfile = async (profile) => {
  const response = await fetch(`${API_URL}/user-profile`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(profile)
  });
  return response.json();
};

export const getWalletBalance = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.warn('No token found in getWalletBalance');
    return { balance: 0, message: 'Please log in to view your balance', error: 'NO_TOKEN' };
  }

  console.log('Token being used for wallet balance request:', token);

  try {
    const response = await fetch(`${API_URL}/wallet-balance`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`  // Changed from 'x-access-token' to 'Authorization'
      }
    });

    console.log('Wallet balance response status:', response.status);

    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        localStorage.removeItem('token');
        return { balance: 0, message: 'Session expired. Please log in again.', error: 'INVALID_TOKEN' };
      }
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Wallet balance data:', data);
    return { balance: data.balance, message: data.message };
  } catch (error) {
    console.error('Error in getWalletBalance:', error);
    return { balance: 0, message: 'Error fetching balance. Please try again.', error: error.message };
  }
};

export const addBalance = async (amount) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }

  const response = await fetch(`${API_URL}/add-balance`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    body: JSON.stringify({ amount })
  });

  if (!response.ok) {
    throw new Error('Failed to add balance');
  }

  return response.json();
};

export const getTransactionHistory = async (page = 1, limit = 10) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found in getTransactionHistory');
    throw new Error('No token found');
  }

  try {
    console.log('Fetching transaction history...');
    const response = await fetch(`${API_URL}/transaction-history?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    console.log('Transaction history response status:', response.status);

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error fetching transaction history:', errorData);
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Transaction history data:', data);
    return data;
  } catch (error) {
    console.error('Error in getTransactionHistory:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

// Add this function to your existing api.js file

export const changePassword = async (currentPassword, newPassword) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found in changePassword');
    throw new Error('No token found. Please log in again.');
  }
  
  console.log('Sending change password request', { currentPassword, newPassword });
  try {
    const response = await fetch(`${API_URL}/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`  // Changed from 'x-access-token' to 'Authorization'
      },
      body: JSON.stringify({ currentPassword, newPassword })
    });
  
    console.log('Change password response status:', response.status);
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Change password response data:', data);
    return data;
  } catch (error) {
    console.error('Error in changePassword:', error);
    throw error;
  }
};

// Other API calls...

export const purchaseLead = async (leadId) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }

  try {
    const response = await fetch(`${API_URL}/purchase-lead`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ leadId })
    });

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const data = await response.json();
      if (!response.ok) {
        console.error('Purchase lead error:', data);
        throw new Error(data.message || 'Failed to purchase lead');
      }
      return data;
    } else {
      // If the response is not JSON, read it as text
      const text = await response.text();
      console.error('Unexpected response:', text);
      throw new Error('Received an unexpected response from the server');
    }
  } catch (error) {
    console.error('Error purchasing lead:', error);
    throw error;
  }
};

export const addTestTransaction = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }

  try {
    const response = await fetch(`${API_URL}/add-test-transaction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error adding test transaction:', error);
    throw error;
  }
};

export const getUserWallet = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }

  try {
    const response = await fetch(`${API_URL}/user/wallet`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching user wallet:', error);
    throw error;
  }
};

export const sendVerificationCode = async (mobile) => {
  try {
    console.log('Sending verification code to:', mobile);
    const response = await fetch(`${API_URL}/send-verification-code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mobile }),
    });
    const data = await response.json();
    console.log('Send verification code response:', data);
    return data;
  } catch (error) {
    console.error('Error sending verification code:', error);
    throw error;
  }
};

export const verifyCode = async (mobile, code) => {
  try {
    const response = await fetch(`${API_URL}/verify-code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mobile, code }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error verifying code:', error);
    throw error;
  }
};

export const checkMobileExists = async (mobile) => {
  try {
    console.log('Checking if mobile exists:', mobile);
    const response = await fetch(`${API_URL}/check-mobile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mobile }),
    });
    const data = await response.json();
    console.log('Check mobile response:', data);
    return data;
  } catch (error) {
    console.error('Error checking if mobile exists:', error);
    throw error;
  }
};

export const resetPassword = async (mobile, newPassword, verificationCode) => {
  try {
    const response = await fetch(`${API_URL}/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mobile, newPassword, verificationCode }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};