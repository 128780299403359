import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getWalletBalance, addBalance, getPurchasedLeads } from '../api';
import BottomNav from './BottomNav';
import { XIcon, CreditCardIcon, ShareIcon } from '@heroicons/react/outline';

const UserWallet = () => {
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    fetchWalletData();
  }, []);

  const fetchWalletData = async () => {
    try {
      console.log('Fetching wallet data...');
      const [balanceResponse, purchasesResponse] = await Promise.all([
        getWalletBalance(),
        getPurchasedLeads()
      ]);

      console.log('Balance response:', balanceResponse);
      console.log('Purchases response:', purchasesResponse);

      if (balanceResponse.error) {
        if (balanceResponse.error === 'NO_TOKEN' || balanceResponse.error === 'INVALID_TOKEN') {
          navigate('/login');
        } else {
          setError(balanceResponse.message);
        }
      } else {
        setBalance(balanceResponse.balance);
      }

      if (Array.isArray(purchasesResponse)) {
        // Sort purchases by purchase_date in descending order
        const sortedPurchases = purchasesResponse.sort((a, b) => 
          new Date(b.purchase_date) - new Date(a.purchase_date)
        );
        setPurchases(sortedPurchases);
        console.log('Sorted purchases:', sortedPurchases);
      } else {
        console.error('Invalid purchases response:', purchasesResponse);
        setError('Failed to fetch purchase history');
      }
    } catch (error) {
      console.error('Error fetching wallet data:', error);
      setError('Failed to load wallet data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddBalance = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await addBalance(Number(amount));
      await fetchWalletData();
      setAmount('');
      setShowPopup(false);
    } catch (error) {
      console.error('Error adding balance:', error);
      setError('Failed to add balance. Please try again.');
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const popupVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8 }
  };

  const popupTransition = {
    type: 'spring',
    damping: 20,
    stiffness: 300
  };

  if (loading) {
    return <div className="min-h-screen bg-gray-100 flex justify-center items-center">Loading...</div>;
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-4">
        <p className="text-red-500 mb-4">{error}</p>
        <button
          onClick={() => navigate('/login')}
          className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700"
        >
          Go to Login
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 pb-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Your Wallet</h2>
        <motion.div
          className="bg-gradient-to-r from-indigo-500 to-purple-500 shadow-lg rounded-lg p-6 mb-6"
          variants={cardVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-xl font-semibold text-white mb-2">Current Balance</p>
              <p className="text-3xl font-bold text-white">₹{balance}</p>
            </div>
            <div>
              <button
                onClick={() => setShowPopup(true)}
                className="px-4 py-2 bg-white text-indigo-600 rounded-lg shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-lg font-semibold"
              >
                Add
              </button>
            </div>
          </div>
        </motion.div>

        {/* Add Balance Popup */}
        {showPopup && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={popupVariants}
            transition={popupTransition}
            className="fixed inset-0 bg-gray-600 bg-opacity-80 overflow-y-auto h-full w-full flex items-center justify-center"
          >
            <div className="bg-white p-8 rounded-lg shadow-xl w-11/12 md:w-1/2 lg:w-1/3 relative">
              <button
                type="button"
                onClick={() => setShowPopup(false)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <XIcon className="h-6 w-6" />
              </button>
              <h3 className="text-2xl font-semibold mb-6 text-center">Add Balance to Wallet</h3>
              <div className="flex flex-col items-center">
                <div className="w-full max-w-xs mb-8">
                  <img 
                    src="/upi_1727251483870.png"
                    alt="UPI QR Code for payment"
                    className="w-full h-auto object-cover rounded-lg shadow-md"
                  />
                </div>
                <a
                  href="upi://pay?pa=bhushanjkulawade@okicici&pn=Leads%20Expert&tn=Leads%20Expert&cu=INR"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center px-6 py-3 bg-indigo-600 text-white rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mb-8"
                >
                  <CreditCardIcon className="h-5 w-5 mr-2" />
                  Make Payment
                </a>
                <div className="text-center">
                  <p className="text-gray-600 mb-4">Once payment is done, click the button below to share the screenshot with us.</p>
                  <a
                    href="https://api.whatsapp.com/send?phone=919097044444&text=Hi%2C%20I%20just%20added%20balance%20to%20my%20leads%20expert%20wallet.%20Here%20is%20screen%20shot%20attached%20for%20your%20reference%20"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-md shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    <ShareIcon className="h-5 w-5 mr-2" />
                    Share Screenshot
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        )}

        <h3 className="text-2xl font-bold text-gray-900 mb-4">Purchase History</h3>
        {purchases.length > 0 ? (
          <motion.div
            className="bg-white shadow overflow-hidden sm:rounded-lg"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
          >
            <ul className="divide-y divide-gray-200">
              {purchases.map((purchase, index) => (
                <motion.li
                  key={purchase.id}
                  className="px-6 py-4"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-gray-900">Lead: {purchase.name}</p>
                      <p className="text-sm text-gray-500">
                        {purchase.purchase_date 
                          ? new Date(purchase.purchase_date).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            })
                          : 'Date not available'}
                      </p>
                    </div>
                    <p className="text-sm font-semibold text-red-600">
                      -₹{purchase.price}
                    </p>
                  </div>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        ) : (
          <p className="text-gray-500">No purchases found.</p>
        )}
      </div>
      <BottomNav />
    </div>
  );
};

export default UserWallet;