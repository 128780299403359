import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { register, sendVerificationCode, verifyCode, checkMobileExists } from '../api';
import { Link, useNavigate } from 'react-router-dom';
import { UserAddIcon } from '@heroicons/react/solid';
import { Button } from './Button';

const SignUp = ({ onSignUp }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [shake, setShake] = useState(false);
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);

  const handleMobileSubmit = async (e) => {
    if (e) e.preventDefault();
    setError('');
    try {
      console.log('Checking if mobile exists:', mobile);
      const response = await checkMobileExists(mobile);
      console.log('Mobile exists response:', response);
      if (!response.exists) {
        console.log('Sending verification code');
        const sendCodeResponse = await sendVerificationCode(mobile);
        console.log('Send code response:', sendCodeResponse);
        if (sendCodeResponse.success) {
          console.log('Setting step to 2');
          setStep(2);
        } else {
          setError(sendCodeResponse.message || 'Failed to send verification code. Please try again.');
        }
      } else {
        setShake(true);
        setTimeout(() => setShake(false), 500);
        setError('Account already exists');
      }
    } catch (err) {
      console.error('Error in handleMobileSubmit:', err);
      setShake(true);
      setTimeout(() => setShake(false), 500);
      setError(err.message || 'An error occurred. Please try again.');
    }
  };

  const handleVerifyCode = async (e) => {
    if (e) e.preventDefault();
    try {
      const isValid = await verifyCode(mobile, verificationCode);
      if (isValid.success) {
        setStep(3);
      } else {
        setError(isValid.message || 'Invalid verification code');
      }
    } catch (err) {
      setError('Failed to verify code');
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      const response = await register(mobile, name, password);
      console.log(response.status);
      if (response.status === 201 || response.success) {
        setError('Registration status: ' + response.data.message);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        setError('Registration failed: ' + response.data.message);
      }
    } catch (err) {
      setError('Server error');
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 10);
    setMobile(value);
  };

  const pageVariants = {
    initial: { opacity: 0, y: 50 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -50 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  const buttonClassName = "group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
      <div className="max-w-md w-full mx-auto px-4 sm:px-6 lg:px-8 space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Create your account</h2>
        </div>
        {step === 1 && (
          <form className="mt-8 space-y-6" onSubmit={handleMobileSubmit}>
            <div>
              <label htmlFor="mobile-number" className="sr-only">Mobile number</label>
              <input
                id="mobile-number"
                name="mobile"
                type="tel"
                required
                pattern="[0-9]{10}"
                title="Please enter a valid 10-digit mobile number"
                className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg"
                placeholder="Mobile number"
                value={mobile}
                onChange={handleMobileChange}
              />
            </div>
            <div>
              <Button
                onClick={handleMobileSubmit}
                className={buttonClassName}
                animate={shake ? { x: [-10, 10, -10, 10, 0] } : {}}
                transition={{ duration: 0.4 }}
              >
                Send Verification Code
              </Button>
            </div>
          </form>
        )}
        {step === 2 && (
          <form className="mt-8 space-y-6" onSubmit={handleVerifyCode}>
            <div>
              <label htmlFor="verification-code" className="sr-only">Verification Code</label>
              <input
                id="verification-code"
                name="verificationCode"
                type="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg"
                placeholder="Verification Code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </div>
            <div>
              <Button
                onClick={handleVerifyCode}
                className={buttonClassName}
              >
                Verify WhatsApp Code
              </Button>
            </div>
          </form>
        )}
        {step === 3 && (
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="sr-only">Name</label>
              <input
                id="name"
                name="name"
                type="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg"
                placeholder="Full name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <Button
                onClick={handleSubmit}
                className={buttonClassName}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <UserAddIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                Sign up
              </Button>
            </div>
          </form>
        )}
        <div className="h-6"> {/* This div reserves space for the error message */}
          {error && <p className="text-center text-sm text-red-600">{error}</p>}
        </div>
        <div className="text-sm text-center">
          <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
            Already have an account? Sign in
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default SignUp;