import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import { Button } from './Button';

const ConfirmationPopup = ({ lead, onConfirm, onCancel }) => {
  const popupVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8 }
  };

  const popupTransition = {
    type: 'spring',
    damping: 20,
    stiffness: 300
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={popupVariants}
      transition={popupTransition}
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center"
    >
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md mx-auto">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Confirm Purchase</h2>
          <motion.button
            onClick={onCancel}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <XCircleIcon className="h-6 w-6" />
          </motion.button>
        </div>
        <div className="mb-8">
          <p className="text-gray-600 mb-2">Are you sure you want to purchase this lead?</p>
          <div className="bg-gray-100 rounded-lg p-4">
            <p className="text-lg font-semibold text-gray-800">{lead.name}</p>
            <p className="text-gray-600">Price: ₹{lead.price}</p>
          </div>
        </div>
        <div className="flex justify-end space-x-4">
          <Button onClick={onCancel} className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
            Cancel
          </Button>
          <Button onClick={onConfirm} className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center">
            <CheckCircleIcon className="h-5 w-5 mr-2" />
            Confirm
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default ConfirmationPopup;