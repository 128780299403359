import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styles from './Button.module.css';

export function Button({ onClick, children, className, disabled, animate }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    if (isLoading || disabled) return;
    setIsLoading(true);
    try {
      await onClick(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.button 
      className={`${styles.button} ${className} ${isLoading ? styles.loading : ''}`}
      onClick={handleClick}
      disabled={disabled || isLoading}
      animate={animate}
    >
      <span className={styles.buttonContent}>
        {children}
      </span>
      {isLoading && (
        <span className={styles.loadingContainer}>
          <div className={styles.loadingWheel}></div>
        </span>
      )}
    </motion.button>
  );
}
