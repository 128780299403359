import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { getLeads, getWalletBalance, purchaseLead } from '../api';
import BottomNav from './BottomNav';
import { SearchIcon, CurrencyRupeeIcon, LocationMarkerIcon, HomeIcon, PhoneIcon, CashIcon } from '@heroicons/react/outline';
import ConfirmationPopup from './ConfirmationPopup';
import { Button } from './Button';

// Helper function to format budget
export const formatBudget = (budget) => {
  if (budget >= 10000000) {
    return `${(budget / 10000000).toFixed(2)} Cr`;
  } else if (budget >= 100000) {
    return `${(budget / 100000).toFixed(2)} Lac`;
  } else if (budget >= 1000) {
    return `${(budget / 1000).toFixed(1)} K`;
  } else if (budget === null) {
    return 'Not Availabe';
  } else {
    return `${budget}`;
  }
};

// Move this function outside of the Dashboard component so it can be exported
export const getStatusColor = (status) => {
  if (!status) return 'bg-gray-100 text-gray-800'; // Default color for undefined status
  switch (status.toLowerCase()) {
    case 'available':
      return 'bg-green-100 text-green-800';
    case 'purchased':
      return 'bg-blue-100 text-blue-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

const Dashboard = () => {
  const [leads, setLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [userBalance, setUserBalance] = useState(0);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [isInitialFetch, setIsInitialFetch] = useState(true);

  const lastLeadElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const fetchLeads = useCallback(async (searchTerm = '', pageNum = 1) => {
    console.log('Fetching leads. Page:', pageNum, 'Search term:', searchTerm);
    if (pageNum === 1) {
      setLeads([]);
      setHasMore(true);
    }
    try {
      setLoading(true);
      const response = await getLeads(pageNum, 5, searchTerm);
      const newLeads = Array.isArray(response) ? response : []; // Ensure newLeads is an array
      console.log('Fetched leads:', newLeads);
      if (newLeads.length === 0) {
        setHasMore(false);
        console.log('No more leads to fetch');
      } else {
        setLeads(prevLeads => [...prevLeads, ...newLeads]);
      }
    } catch (err) {
      console.error('Error fetching leads:', err);
      setError('Failed to fetch leads');
    } finally {
      setLoading(false);
      setInitialLoadDone(true);
      setIsInitialFetch(false);
    }
  }, []);

  useEffect(() => {
    if (isInitialFetch) {
      fetchLeads('', 1);
    }
  }, [fetchLeads, isInitialFetch]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchTerm !== debouncedSearchTerm) {
        setDebouncedSearchTerm(searchTerm);
        setPage(1);
        fetchLeads(searchTerm, 1);
      }
    }, 500);

    return () => clearTimeout(timerId);
  }, [searchTerm, debouncedSearchTerm, fetchLeads]);

  useEffect(() => {
    if (page > 1 && !isInitialFetch) {
      fetchLeads(debouncedSearchTerm, page);
    }
  }, [page, debouncedSearchTerm, fetchLeads, isInitialFetch]);

  useEffect(() => {
    console.log('Initial data fetch effect triggered');
    let isMounted = true;
    
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found, redirecting to login');
        navigate('/login');
        return;
      }

      try {
        const balanceResponse = await getWalletBalance();
        console.log('Wallet balance response:', balanceResponse);
        if (isMounted) {
          if (balanceResponse.error) {
            if (balanceResponse.error === 'INVALID_TOKEN') {
              console.log('Invalid token, clearing and redirecting to login');
              localStorage.removeItem('token');
              setIsLoggedIn(false);
              navigate('/login');
            } else {
              setError(balanceResponse.message);
            }
          } else {
            setUserBalance(balanceResponse.balance);
            setError(null);
          }
        }
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
        if (isMounted) {
          setError('Failed to fetch data. Please try again later.');
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [isLoggedIn, navigate]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleBuyClick = (lead) => {
    setSelectedLead(lead);
    setShowConfirmation(true);
  };

  const handleConfirmPurchase = async () => {
    if (!isLoggedIn) {
      console.log('User not logged in, redirecting to login...');
      alert('Please log in to purchase leads.');
      navigate('/login');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in handleConfirmPurchase');
        setIsLoggedIn(false);
        navigate('/login');
        return;
      }

      console.log('Attempting to purchase lead:', selectedLead.id);
      console.log('Lead price:', selectedLead.price);
      console.log('Token being used:', token);
      const purchaseResponse = await purchaseLead(selectedLead.id);
      console.log('Purchase response:', purchaseResponse);
      
      if (purchaseResponse.newBalance !== undefined) {
        setUserBalance(purchaseResponse.newBalance);
        console.log('User balance updated:', purchaseResponse.newBalance);
        
        // Remove the purchased lead from the local state
        setLeads(prevLeads => prevLeads.filter(lead => lead.id !== selectedLead.id));
        console.log('Lead removed from list:', selectedLead.id);
      }

      setShowConfirmation(false);
      alert(`Lead purchased successfully for ₹${selectedLead.price}!`);
      navigate('/purchased-leads');
    } catch (error) {
      console.error('Error purchasing lead:', error);
      alert(`Failed to purchase lead: ${error.message}`);
      if (error.message.includes('No token found') || error.message.includes('Invalid token')) {
        setIsLoggedIn(false);
        navigate('/login');
      }
    }
  };

  const handleListenRecording = (leadId) => {
    // Implement the logic to play the recording
    console.log(`Playing recording for lead ${leadId}`);
    // You might want to navigate to a new page or open a modal to play the recording
  };

  const pageVariants = {
    initial: { opacity: 0, y: 50 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -50 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {isLoggedIn ? (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
          className="flex-grow pb-16"
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Dashboard</h2>
            <div className="mb-6">
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  placeholder="Search leads..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-lg border-gray-300 rounded-md appearance-none rounded-md w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                />
              </div>
            </div>
            {loading && leads.length === 0 ? (
              <p className="text-center text-gray-500">Loading leads...</p>
            ) : error ? (
              <p className="text-center text-red-500">{error}</p>
            ) : leads.length > 0 ? (
              <AnimatePresence>
                <motion.ul
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  className="space-y-4"
                >
                  {leads.map((lead, index) => (
                    <motion.li
                      key={lead.id}
                      ref={index === leads.length - 1 ? lastLeadElementRef : null}
                      variants={itemVariants}
                      className="bg-white shadow-md rounded-lg p-6 mb-4 hover:shadow-lg transition-shadow duration-300"
                    >
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-semibold text-gray-800">{lead.name}</h3>
                        <div className="flex items-center">
                          <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(lead.status)}`}>
                            {lead.status}
                          </span>
                          <p className="ml-4 text-lg font-semibold text-indigo-600 flex items-center">
                            <CurrencyRupeeIcon className="h-5 w-5 mr-1" />
                            {formatBudget(lead.price)}
                          </p>
                        </div>
                      </div>
                      <p className="text-gray-600 mb-4">{lead.description}</p>
                      <div className="grid grid-cols-2 gap-4 mb-4">
                        <div className="flex items-center">
                          <HomeIcon className="h-5 w-5 text-gray-400 mr-2" />
                          <div>
                            <p className="text-sm text-gray-500">Configuration</p>
                            <p className="text-sm text-gray-800">{lead.configuration || 'Not specified'}</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm1 5a1 1 0 100 2h6a1 1 0 100-2H4z" clipRule="evenodd" />
                          </svg>
                          <div>
                            <p className="text-sm text-gray-500">Carpet Area</p>
                            <p className="text-sm text-gray-800">{lead.carpet ? `${lead.carpet} sq ft` : 'Not specified'}</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <CashIcon className="h-5 w-5 text-gray-400 mr-2" />
                          <div>
                            <p className="text-sm text-gray-500">Budget</p>
                            <p className="text-sm text-gray-800">{formatBudget(lead.budget)}</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <LocationMarkerIcon className="h-5 w-5 text-gray-400 mr-2" />
                          <div>
                            <p className="text-sm text-gray-500">Location</p>
                            <p className="text-sm text-gray-800">{lead.location || 'Not specified'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end items-center mt-8">
                        {/* <Button
                          onClick={() => handleListenRecording(lead.id)}
                          className="px-4 py-2 bg-indigo-100 text-indigo-600 rounded-md hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 flex items-center"
                        >
                          <PhoneIcon className="h-5 w-5 mr-2" />
                          Purchase for Meeting
                        </Button> */}
                        <Button
                          onClick={() => handleBuyClick(lead)}
                          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 flex items-center"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <CashIcon className="h-5 w-5 mr-2" />
                          Buy
                        </Button>
                      </div>
                    </motion.li>
                  ))}
                </motion.ul>
              </AnimatePresence>
            ) : (
              <p className="text-center text-gray-500 mt-8">No leads found matching your search.</p>
            )}
            {loading && leads.length > 0 && <p className="text-center mt-4">Loading more leads...</p>}
            {!hasMore && leads.length > 0 && <p className="text-center mt-4">That's it. Check back after some time for more leads.</p>}
          </div>
        </motion.div>
      ) : (
        <p className="text-center text-gray-500 mt-8">Please log in to view the dashboard.</p>
      )}
      {showConfirmation && (
        <ConfirmationPopup
          lead={selectedLead}
          onConfirm={handleConfirmPurchase}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
      <BottomNav />
    </div>
  );
};

export default Dashboard;