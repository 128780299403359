import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getPurchasedLeads } from '../api';
import { format } from 'date-fns';
import BottomNav from './BottomNav';
import { useNavigate } from 'react-router-dom';
import { SearchIcon, PhoneIcon, HomeIcon, LocationMarkerIcon, CashIcon } from '@heroicons/react/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { getStatusColor, formatBudget } from './Dashboard'; // Import the function

const PurchasedLeads = () => {
  const [purchasedLeads, setPurchasedLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  const lastLeadElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const fetchPurchasedLeads = useCallback(async () => {
    try {
      setLoading(true);
      const newLeads = await getPurchasedLeads(page);
      if (newLeads.length === 0) {
        setHasMore(false);
      } else {
        setPurchasedLeads(prevLeads => {
          const uniqueNewLeads = newLeads.filter(newLead => 
            !prevLeads.some(prevLead => prevLead.id === newLead.id)
          );
          return [...prevLeads, ...uniqueNewLeads];
        });
      }
    } catch (err) {
      console.error('Error fetching purchased leads:', err);
      setError('Failed to fetch purchased leads');
      if (err.message.includes('No token found') || err.message.includes('Invalid token')) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  }, [page, navigate]);

  useEffect(() => {
    fetchPurchasedLeads();
  }, [fetchPurchasedLeads]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredLeads = purchasedLeads.filter(lead => {
    return lead.name.toLowerCase().includes(search.toLowerCase());
  });

  const handleCall = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const pageVariants = {
    initial: { opacity: 0, y: 50 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -50 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  if (loading && purchasedLeads.length === 0) return <div className="min-h-screen bg-gray-100 flex justify-center items-center">Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className="flex-grow pb-16"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Your Purchased Leads</h2>
          {purchasedLeads.length > 0 && (
            <div className="mb-6">
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  placeholder="Search leads..."
                  value={search}
                  onChange={handleSearch}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-lg border-gray-300 rounded-md appearance-none rounded-md w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                />
              </div>
            </div>
          )}
          {loading && purchasedLeads.length === 0 ? (
            <p className="text-center text-gray-500">Loading leads...</p>
          ) : error ? (
            <p className="text-center text-red-500">{error}</p>
          ) : filteredLeads.length > 0 ? (
            <AnimatePresence>
              <motion.ul
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                className="space-y-4"
              >
                {filteredLeads.map((lead, index) => (
                  <motion.li
                    key={lead.id}
                    ref={index === filteredLeads.length - 1 ? lastLeadElementRef : null}
                    variants={itemVariants}
                    className="bg-white shadow-md rounded-lg p-6 mb-4 hover:shadow-lg transition-shadow duration-300"
                  >
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-xl font-semibold text-gray-800">{lead.name}</h3>
                      <div className="flex items-center">
                        <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(lead.status)}`}>
                          {lead.status || 'Unknown'}
                        </span>
                      </div>
                    </div>
                    <p className="text-gray-600 mb-4">{lead.description}</p>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div className="flex items-center">
                        <HomeIcon className="h-5 w-5 text-gray-400 mr-2" />
                        <div>
                          <p className="text-sm text-gray-500">Configuration</p>
                          <p className="text-sm text-gray-800">{lead.configuration || 'Not specified'}</p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400 mr-2" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm1 5a1 1 0 100 2h6a1 1 0 100-2H4z" clipRule="evenodd" />
                        </svg>
                        <div>
                          <p className="text-sm text-gray-500">Carpet Area</p>
                          <p className="text-sm text-gray-800">{lead.carpet ? `${lead.carpet} sq ft` : 'Not specified'}</p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <CashIcon className="h-5 w-5 text-gray-400 mr-2" />
                        <div>
                          <p className="text-sm text-gray-500">Budget</p>
                          <p className="text-sm text-gray-800">{formatBudget(lead.budget)}</p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <LocationMarkerIcon className="h-5 w-5 text-gray-400 mr-2" />
                        <div>
                          <p className="text-sm text-gray-500">Location</p>
                          <p className="text-sm text-gray-800">{lead.location || 'Not specified'}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-center mt-8">
                      <p className="text-sm text-gray-500">
                        Purchased on: {format(new Date(lead.purchase_date), 'PPp')}
                      </p>
                      <button
                        onClick={() => handleCall(lead.phone)}
                        className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 flex items-center"
                      >
                        <PhoneIcon className="h-5 w-5 mr-2" />
                        Contact
                      </button>
                    </div>
                  </motion.li>
                ))}
              </motion.ul>
            </AnimatePresence>
          ) : (
            <p className="text-center text-gray-500 mt-8">You haven't purchased any leads yet.</p>
          )}
          {loading && purchasedLeads.length > 0 && <p className="text-center mt-4">Loading more leads...</p>}
          {!hasMore && <p className="text-center mt-4">No more purchased leads to load.</p>}
        </div>
      </motion.div>
      <BottomNav />
    </div>
  );
};

export default PurchasedLeads;