import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaUser, FaWallet, FaList } from 'react-icons/fa'; // Import icons from react-icons

const BottomNav = () => {
  const location = useLocation();

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-2 flex justify-around z-50">
      <Link to="/dashboard" className={`flex flex-col items-center ${location.pathname === '/dashboard' ? 'text-blue-500' : 'text-gray-500'}`}>
        <FaHome size={24} />
        <span className="text-xs">Dashboard</span>
      </Link>
      <Link to="/purchased-leads" className={`flex flex-col items-center ${location.pathname === '/purchased-leads' ? 'text-blue-500' : 'text-gray-500'}`}>
        <FaList size={24} />
        <span className="text-xs">My Leads</span>
      </Link>
      <Link to="/user-wallet" className={`flex flex-col items-center ${location.pathname === '/user-wallet' ? 'text-blue-500' : 'text-gray-500'}`}>
        <FaWallet size={24} />
        <span className="text-xs">Wallet</span>
      </Link>
      <Link to="/user-profile" className={`flex flex-col items-center ${location.pathname === '/user-profile' ? 'text-blue-500' : 'text-gray-500'}`}>
        <FaUser size={24} />
        <span className="text-xs">Profile</span>
      </Link>
    </div>
  );
};

export default BottomNav;