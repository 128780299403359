import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { checkMobileExists, sendVerificationCode, verifyCode, resetPassword } from '../api';
import { Button } from './Button';
import { LockClosedIcon } from '@heroicons/react/solid';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [shake, setShake] = useState(false);
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  useEffect(() => {
    console.log('Step changed to:', step);
  }, [step]);

  const handleMobileSubmit = async (e) => {
    if (e) e.preventDefault();
    setError('');
    try {
      console.log('Checking if mobile exists:', mobile);
      const response = await checkMobileExists(mobile);
      console.log('Mobile exists response:', response);
      if (response.exists) {
        console.log('Sending verification code');
        const sendCodeResponse = await sendVerificationCode(mobile);
        console.log('Send code response:', sendCodeResponse);
        if (sendCodeResponse.success) {
          console.log('Setting step to 2');
          setStep(2);
        } else {
          setError(sendCodeResponse.message || 'Failed to send verification code. Please try again.');
        }
      } else {
        setShake(true);
        setTimeout(() => setShake(false), 500);
        setError('Mobile number not found');
      }
    } catch (err) {
      console.error('Error in handleMobileSubmit:', err);
      setShake(true);
      setTimeout(() => setShake(false), 500);
      setError(err.message || 'An error occurred. Please try again.');
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 10);
    setMobile(value);
  };

  const handleCodeVerification = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const verifyResponse = await verifyCode(mobile, verificationCode);
      if (verifyResponse.success) {
        setStep(3);
      } else {
        setError(verifyResponse.message || 'Invalid verification code');
      }
    } catch (err) {
      setError(err.message || 'Failed to verify code. Please try again.');
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError('');
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await resetPassword(mobile, newPassword);
      setError('Password reset successfully');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      setError(err.message);
    }
  };

  const buttonClassName = "group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

  const pageVariants = {
    initial: { opacity: 0, y: 50 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -50 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
      <div className="max-w-md w-full mx-auto px-4 sm:px-6 lg:px-8 space-y-8">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot Password</h2>
        {console.log('Current step:', step)}
        <div className="mt-8 space-y-6">
          {step === 1 && (
            <form onSubmit={handleMobileSubmit}>
              <div>
                <input
                  type="tel"
                  value={mobile}
                  onChange={handleMobileChange}
                  placeholder="Enter your mobile number"
                  required
                  pattern="[0-9]{10}"
                  title="Please enter a valid 10-digit mobile number"
                  className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg"
                />
              </div>
              <div className="mt-6">
                <Button
                  onClick={handleMobileSubmit}
                  className={buttonClassName}
                  animate={shake ? { x: [-10, 10, -10, 10, 0] } : {}}
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                  </span>
                  Send Verification Code
                </Button>
              </div>
            </form>
          )}
          {step === 2 && (
            <form onSubmit={handleCodeVerification}>
              <div>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter verification code"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg"
                />
              </div>
              <div className="mt-6">
                <Button
                  onClick={handleCodeVerification}
                  className={buttonClassName}
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                  </span>
                  Verify Code
                </Button>
              </div>
            </form>
          )}
          {step === 3 && (
            <form onSubmit={handlePasswordReset}>
              <div>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter new password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg"
                />
              </div>
              <div className="mt-6">
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm new password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg"
                />
              </div>
              <div className="mt-6">
                <Button
                  onClick={handlePasswordReset}
                  className={buttonClassName}
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                  </span>
                  Reset Password
                </Button>
              </div>
            </form>
          )}
        </div>
        <div className="h-6">
          {error && <p className="text-center text-sm text-red-600">{error}</p>}
        </div>
        <div className="text-sm text-center">
          <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
            Remember password? Login now
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default ForgotPassword;